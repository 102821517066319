import React from 'react';
import './TKLoaderContainer.scss';
import TKLoader from './TKLoader/TKLoader';

const TKLoaderContainer = () => {
  return (
    <div className="tk_loader-container">
      <TKLoader/>
    </div>
  );
};

export default TKLoaderContainer;