import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TKPageHeader from '../../../../components/TKPageHeader/TKPageHeader';
import TKAccordion from '../../../../components/TKAccordion/TKAccordion';
import { 
  getTKFAQAdminRequest
}
from "../../../../store/actions/faqs";

const FAQ = () => {

  const {list} = useSelector(state => state.faqs.admin);
  const dispatch = useDispatch();


  useEffect(() => {
    getFAQList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const getFAQList = () => {
    dispatch(getTKFAQAdminRequest({}));
  }

  return (
    <>
      <TKPageHeader header="sık sorulan sorular"/>
      <div className="container">
      <div style={{marginTop: "-30px"}}>
        <TKAccordion listFAQ={list}/>
      </div>
      </div>
      
    </>
  );
};

export default FAQ;