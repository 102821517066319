import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TKAccordion from "../../../../components/TKAccordion/TKAccordion";
import TKPageHeader from '../../../../components/TKPageHeader/TKPageHeader';
import TKTabs from '../../../../components/TKTabs/TKTabs';
import { 
  getTKTKNAdminRequest
}
from "../../../../store/actions/tkns";

const TuzlaKartInfo = () => {

  const {list} = useSelector(state => state.tkns.admin);
  const dispatch = useDispatch();
  const numRows = list.length

  useEffect(() => {
    getTKNList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const getTKNList = () => {
    dispatch(getTKTKNAdminRequest({}));
  }

  return (
    <>
          <div className="">
         <TKPageHeader header="tuzla kart nedir?"/>
      <div className="container">
            <div className="tk-tab-res">
              <TKTabs list={list}/>
            </div>
            <div className="tk-acc-res">
              <TKAccordion listFAQ={list}/>
            </div>
      </div>
         </div>

    </>
  );
};

export default TuzlaKartInfo;