import React from "react";
import "./TKTabs.scss";
import { Tab, Nav } from "react-bootstrap";
import TKIcon from "../TKIcon/TKIcon";
import tuzlakartnedir from "../../assets/images/tuzla-kart-nedir.png";
import genckartnedir from "../../assets/images/genc-kart-nedir.JPG";
import tuzlakartnerelerde from "../../assets/images/tuzla-kart-nereden-alabilirim.JPG";
import tuzlakartnereden from "../../assets/images/tuzla-kart-nerelerde-kullanılır.JPG";
import nerede1 from "../../assets/images/nerede_1.JPG";
import nerede2 from "../../assets/images/nerede_2.jpeg";
import nerede3 from "../../assets/images/nerede_3.JPG";
import parse from "html-react-parser";

import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { isJsonString } from "../../helpers/utilities";
const TKTabs = ({ list }) => {
  return (
    <Tab.Container defaultActiveKey="0">
      <div className="tk_tab-container">
        <Nav variant="pills" className="flex-column tk_tab-menu">
          {list.map((item, index) => (
            <Nav.Item>
              <Nav.Link eventKey={index}>
                {item.caption}
                <div className="tk_tab-icon">
                  <TKIcon name="arrow" color="#09072f" size="13" />
                </div>
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        {console.log(window.location.pathname)}
        <Tab.Content className="tk_tab-content">
          {list.map((item, index) => {
            let image;
            if (
              item.caption == "Tuzla Kart Nedir?" &&
              window.location.pathname == "/tuzla-kart-nedir"
            ) {
              image =
                '<img style="width:100%" src="' +
                tuzlakartnedir +
                '" />' +
                item.detail;
            } else if (item.caption == "Tuzla Kart Nerede Kullanılır?") {
              image =
                '<img style="width:100%" src="' +
                tuzlakartnereden +
                '" />' +
                item.detail +
                '<img style="width:100%" src="' +
                nerede1 +
                '" /> <br>' +
                '<img style="width:100%" src="' +
                nerede2 +
                '" />  <br>' +
                '<img style="width:100%" src="' +
                nerede3 +
                '" />';
            } else if (
              item.caption == "Tuzla Kart'ı Nereden/Nasıl Alabilirim?"
            ) {
              image =
                '<img style="width:100%" src="' +
                tuzlakartnerelerde +
                '" />' +
                item.detail;
            } else if (
              item.caption == "Genç Kart Nedir?" &&
              window.location.pathname == "/genc-kart-nedir"
            ) {
              image =
                '<img style="width:100%" src="' +
                genckartnedir +
                '" />' +
                item.detail;
            } else {
              image = item.detail;
            }
            return (
              <Tab.Pane style={{ width: "700px" }} eventKey={index}>
                {isJsonString(item.detail) ? (
                  <Editor
                    toolbarHidden
                    readOnly
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    editorState={EditorState.createWithContent(
                      convertFromRaw(JSON.parse(item.detail))
                    )}
                  />
                ) : (
                  parse(item.detail)
                )}
              </Tab.Pane>
            );
          })}
        </Tab.Content>
      </div>
    </Tab.Container>
  );
};

export default TKTabs;
